import { PlusCircleOutlined } from '@ant-design/icons'
import { Input, Space } from 'antd'
import React, { FC, useState } from 'react'
import classNames from 'classnames'
interface IUserWalletComponentProps {
  onChangeValue: (value: string, isError: boolean) => void
  defaultValue?: string
  className?: string
  errorClassName?: string
}
const InputComponent: FC<IUserWalletComponentProps> = ({ onChangeValue, defaultValue, className, errorClassName }) => {
  const [nickName, setNickName] = useState('')
  const [nickState, setNickState] = useState<'error' | ''>('')
  const reg = /^[\u4e00-\u9fa5|a-zA-Z|0-9_-]+$/g

  const customCountShow = (opt: { count: number; maxLength?: number }) => (
    <Space>{(opt.maxLength ?? 30) - opt.count}characters left</Space>
  )
  const handleChange = (e: any) => {
    setNickName(e.target.value)
  }
  const handleChangeValidate = (e: any) => {
    if (reg.test(e.target.value)) {
      setNickState('')
      onChangeValue(e.target.value, false)
    } else {
      setNickState('error')
      onChangeValue(e.target.value, true)
    }
  }
  return (
    <>
      <Input
        className={classNames(className, 'w-full h-full border-[#E5E6ED] hover:border-primary-black')}
        placeholder={defaultValue}
        status={nickState}
        // defaultValue={defaultValue}
        suffix={
          nickName && nickName.length > 0 ? (
            <PlusCircleOutlined className="text-lg ml-2 leading-3" rotate={45} onClick={() => setNickName('')} />
          ) : (
            <span></span>
          )
        }
        value={nickName}
        onChange={handleChange}
        onBlur={handleChangeValidate}
        showCount={{ formatter: customCountShow }}
        maxLength={30}
      />
      {/* absolute top-[110%] */}
      <div className={classNames('text-red-600 w-full', errorClassName, { 'mt-4': nickState === 'error' })}>
        {nickState === 'error'
          ? 'Only Chinese and English characters, numbers, underscores and minus signs are allowed.'
          : ''}
      </div>
    </>
  )
}
export default InputComponent
