import copy from 'copy-to-clipboard'
import { useCallback, useEffect, useState } from 'react'
export function useCopyClipboard(timeout = 3000): [boolean, (text: string) => void] {
  const [isCopied, setCopied] = useState(false)
  const copyFn = useCallback((text) => {
    const copied = copy(text)
    setCopied(copied)
  }, [])
  useEffect(() => {
    if (isCopied) {
      const id = setTimeout(() => {
        setCopied(false)
      }, timeout)
      return () => clearTimeout(id)
    }
  }, [isCopied, timeout, setCopied])
  return [isCopied, copyFn]
}
