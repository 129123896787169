import React, { useEffect, useState } from 'react'
import { Button, Layout, Popover, Spin, Tooltip } from 'antd'
const { Sider, Content } = Layout
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
// import BrandImg from '@/assets/menu/brand.png'
// import HomeImg from '@/assets/menu/home.png'
import avatorImg from '@/assets/avator.png'
// import ProfileImg from '@/assets/menu/profile.png'
// import ExploreImg from '@/assets/menu/explore.png'
import { useAppDispatch, useAppSelector } from '@/store/hook'
import UserWalletComponent from '@/components/user'
import useUserLogin from '@/hooks/useUserLogin'
import useChangeAccount from '@/hooks/useChangeAccount'

const BrandSvg = (
  <svg className="icon" aria-hidden="true">
    <use xlinkHref="#icon-icon-Logo"></use>
  </svg>
)

const ProfileSVG = (
  <svg className="icon" aria-hidden="true">
    <use xlinkHref="#icon-icon-explore-default"></use>
  </svg>
)
const ExploreSVG = (
  <svg className="icon" aria-hidden="true">
    <use xlinkHref="#icon-a-icon-explore-default2"></use>
  </svg>
)
const ClubSVG = (
  <svg className="icon" aria-hidden="true">
    <use xlinkHref="#icon-icon-club-default"></use>
  </svg>
)
const ProfileHoverSVG = (
  <svg className="icon" aria-hidden="true">
    <use xlinkHref="#icon-a-icon-explore-selected2"></use>
  </svg>
)
const ExploreHoverSVG = (
  <svg className="icon" aria-hidden="true">
    <use xlinkHref="#icon-icon-explore-selected"></use>
  </svg>
)
const ClubHoverSVG = (
  <svg className="icon" aria-hidden="true">
    <use xlinkHref="#icon-icon-club-selected"></use>
  </svg>
)

const LayoutPage = () => {
  const dispatch = useAppDispatch()
  const loading = useAppSelector((state) => state.page.loading)
  const navigator = useNavigate()
  const local = useLocation()
  const errorMsg = useAppSelector((state) => state.page.error)
  const warningMsg = useAppSelector((state) => state.page.warning)
  const user = useAppSelector((state) => state.wallet.user)
  const [visible, setVisible] = useState(false)

  useUserLogin(dispatch)
  useChangeAccount(dispatch)

  useEffect(() => {
    // 暂时先不弹出error错误信息
    // errorMsg && message.error(errorMsg)
  }, [errorMsg])
  useEffect(() => {
    warningMsg && window.message.warning(warningMsg)
  }, [warningMsg])

  const _profileLink = () => {
    return navigator('/profile', { replace: true })
  }
  const _exploreLink = () => {
    return navigator('/explore', { replace: true })
  }
  const _clubLink = () => {
    return navigator('/club', { replace: true })
  }
  return (
    // min-w-[90rem]
    <Layout className="w-screen h-screen relative font-inter min-w-[62.5rem]">
      <Sider width={76} className="flex flex-col items-center relative bg-white border-0 border-r border-slate-200 ">
        <div className="text-3xl mb-52 mt-6">{BrandSvg}</div>
        {/* <img src={BrandImg} className="w-8 h-8 mb-52 mt-6" alt="brand" /> */}
        <Tooltip title="Profile" placement="right">
          <div className="text-2xl text-center mb-16 cursor-pointer pl-[3px]" onClick={_profileLink}>
            {local.pathname.includes('/profile') ? ProfileHoverSVG : ProfileSVG}
          </div>
          {/* <img src={HomeImg} className="w-6 h-6 mb-16" alt="home" onClick={_profileLink} /> */}
        </Tooltip>
        <Tooltip title="Explore" placement="right">
          <div
            className="text-2xl text-center mb-16 cursor-pointer pl-[3px]"
            onClick={() => {
              _exploreLink()
            }}
          >
            {local.pathname.includes('/explore') ||
            local.pathname.includes('/overviewTop') ||
            local.pathname.includes('/overviewUpcoming')
              ? ExploreHoverSVG
              : ExploreSVG}
          </div>
          {/* <img
            src={ProfileImg}
            className="w-6 h-6 mb-16"
            alt="profile"
            onClick={() => {
              _exploreLink()
            }}
          /> */}
        </Tooltip>
        <Tooltip title="Club" placement="right">
          <div
            className="text-2xl text-center mb-16 cursor-pointer pl-[3px]"
            onClick={() => {
              _clubLink()
            }}
          >
            {local.pathname.includes('/club') ? ClubHoverSVG : ClubSVG}
          </div>
          {/* <img
            src={ExploreImg}
            className="w-[1.875rem] h-[1.875rem] mb-16"
            alt="explore"
            onClick={() => {
              _clubLink()
            }}
          /> */}
        </Tooltip>
        {console.log('v:', visible)}
        {user?.wallets && user.wallets.length > 0 ? (
          <Popover
            defaultVisible={false}
            trigger={['click']}
            visible={visible}
            placement="topLeft"
            content={<UserWalletComponent onShowPanel={() => setVisible(false)} name={user.nickname ?? ''} />}
            onVisibleChange={(v) => setVisible(v)}
            arrowPointAtCenter={true}
          >
            <Button shape="circle" icon={<img src={avatorImg} />} className="absolute bottom-6 w-8 h-8 border-0" />
          </Popover>
        ) : null}
      </Sider>
      <Content className="overflow-auto bg-white">
        <Outlet />
      </Content>
      {loading ? (
        <Spin className="absolute top-0 left-0 w-full h-full flex justify-center items-center" size="large" />
      ) : null}
    </Layout>
  )
}
export default LayoutPage
