import { INickNameRet, IUser } from '@/entity/service.interface'
import { localUserKey } from '@/entity/system.interface'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { message } from 'antd'
// import { message } from 'antd'
import { changeError } from './page'

interface IWalletState {
  loading: boolean // 钱包loading
  user?: IUser
  iserror?: boolean
  changedAddress?: string
  addLoading: boolean
}
const initialState: IWalletState = {
  loading: false,
  user: undefined,
  addLoading: false,
}
const loginThunk = createAsyncThunk<IUser>('wallet_login', async (_, { dispatch }): Promise<IUser> => {
  dispatch(changeLoading(true))
  try {
    let saveUser: IUser | undefined = undefined
    let user = window.Moralis.User.current()
    // 获取下metamask当前的账户,可能change accound后，current获取到的账户还是之前的。
    const metamaskAccount = window.Moralis.account
    // 如果没有获取到用户，则认证一下
    // if (!user || metamaskAccount !== user) {
    //   user = await window.Moralis.authenticate({ signingMessage: 'SpaceN Authoriation' })
    // }
    if (!user) {
      user = await window.Moralis.authenticate({ signingMessage: 'SpaceN Authoriation' })
    }
    // 获取下当前账户下的链路类型
    const chanid = window.Moralis.chainId
    console.log('process.env.REACT_APP_BLOCK_CHAIN:', process.env.REACT_APP_BLOCK_CHAIN, chanid)
    if (chanid !== process.env.REACT_APP_BLOCK_CHAIN) {
      throw Error(`Please switch to Ethereum mainnet`)
    }
    console.log('get user from metamask:', user)
    // 也可以直接通过user.attributes.获取相关数据
    const address = user ? user.get('ethAddress') : metamaskAccount // 当前地址
    // const accounts = user.get('accounts') // 账号列表
    saveUser = {
      objectId: user.id,
      currentAddress: address,
      wallets: [{ walletname: 'wallet01', address: address }],
      token: user.get('sessionToken'),
    }
    // 进行登录
    const params = { addr: address }
    const walletList = await window.Moralis.Cloud.run('nftLogin', params)
    console.log('nftlogin:', walletList)
    if (walletList.success) {
      const data = walletList.data[0]
      if (data) {
        // 登录成功
        Object.assign(saveUser, {
          objectId: data.id,
          currentAddress: address,
          wallets: data.get('addrs'),
          nickname: data.get('nickName'),
        })
      }
    } else {
      // 登录失败, 重置user
      Object.assign(saveUser, { objectId: undefined })
    }
    return saveUser
  } catch (e: any) {
    dispatch(changeError(e.message))
    message.error(e.message)
    throw e
  } finally {
    dispatch(changeLoading(false))
  }
})

export interface IUpdateAccountOpt {
  objectId?: string
  nickName: string
  avator?: string
  addrs: { walletname?: string; address: string; main?: boolean }[]
}

// 修改昵称
const regNickNameThunk = createAsyncThunk<INickNameRet, IUpdateAccountOpt>(
  'wallet_reg_nickname',
  async (opt: IUpdateAccountOpt, { dispatch }): Promise<INickNameRet> => {
    dispatch(changeLoading(true))
    try {
      console.log('upsertAccount opt:', opt)
      const walletList = await window.Moralis.Cloud.run('upsertAccount', opt)
      console.log('upsertAccount::', walletList)
      if (walletList.success && walletList.data) {
        const addressList = walletList.data.get ? walletList.data.get('addrs') : walletList.data.addrs
        const nickName = walletList.data.get ? walletList.data.get('nickName') : walletList.data.nickName
        console.log('nick::', nickName, addressList, walletList.data.id || walletList.data.objectId, walletList.data)
        return { objectId: walletList.data.id || walletList.data.objectId, nickname: nickName, wallets: addressList }
      } else {
        throw new Error(walletList.data)
      }
    } catch (e: any) {
      dispatch(changeError(e.message))
      throw e
    } finally {
      dispatch(changeLoading(false))
    }
  },
)
// 添加钱包
const addAddressToWebThunk = createAsyncThunk(
  'wallet_address_is_right',
  async (address: string | undefined, { dispatch, getState }) => {
    dispatch(changeLoading({ key: 'addwallet', value: true }))
    try {
      if (address) {
        console.log('changeAccount:', address)
        const state: any = getState()
        const user: IUser = state && state.wallet && state.wallet.user
        // 查询此地址在钱包列表没
        if (user && user.wallets && user.wallets.findIndex((r) => r.address === address) >= 0) {
          return window.message.warning('Address already registered')
        }
        try {
          await window.Moralis.link(address)
        } catch (e: any) {
          if (e.code === 208) {
            // 此账户已经使用过
            window.message.warning('Address already registered')
            return
          }
        }
        // 使用此地址，调用upsertAccount，来更新钱包
        const len = user.wallets.length > 9 ? user.wallets.length + 1 : `0${user.wallets.length + 1}`
        const opt: IUpdateAccountOpt = {
          objectId: user.objectId,
          nickName: user.nickname ?? '',
          addrs: user.wallets.concat({ walletname: `wallet${len}`, address: address }),
        }
        await dispatch(regNickNameThunk(opt)).unwrap()
        window.message.success('Registration successful')
      }
    } catch (e: any) {
      console.log('e--', e)
      window.message.warning('Registration failed')
    } finally {
      dispatch(changeLoading({ key: 'addwallet', value: false }))
    }
  },
)
const SaveUserToLocal = (saved: any) => {
  const info = sessionStorage.getItem(localUserKey)
  const pInfo = info && JSON.parse(info)
  if (pInfo && saved) {
    console.log('pinfo, saved::', pInfo, saved)
    sessionStorage.setItem(localUserKey, JSON.stringify(Object.assign({}, pInfo, saved)))
  } else {
    sessionStorage.setItem(localUserKey, JSON.stringify(saved))
  }
}
// 退出登录
const layoutThunk = createAsyncThunk('wallet_layout', async (_, { dispatch }) => {
  dispatch(changeLoading(true))
  try {
    await window.Moralis.User.logOut()
    dispatch(setUserInfoByLocal(undefined))
    sessionStorage.removeItem(localUserKey)
    window.message.info('You logged out successfully!')
  } catch (e) {
  } finally {
    dispatch(changeLoading(false))
  }
})
const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    changeLoading: (state, actions) => {
      if (typeof actions.payload === 'boolean') {
        state.loading = actions.payload
      } else {
        switch (actions.payload.key) {
          case 'addwallet':
            state.addLoading = actions.payload.value
            break
        }
      }
    },
    changeAddressLink: (state, actions) => {
      state.user = Object.assign({}, state.user, { currentAddress: actions.payload })
    },
    setUserInfoByLocal: (state, actions) => {
      console.log('actions----', actions.payload)
      if (actions.payload) {
        state.user = Object.assign({}, state.user, actions.payload)
      } else {
        state.user = undefined
      }
    },
    setChangedAccount: (state, actions) => {
      console.log('change account---', actions.payload)
      state.changedAddress = actions.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginThunk.fulfilled, (state, actions) => {
        state.user = actions.payload
        SaveUserToLocal(actions.payload)
      })
      .addCase(regNickNameThunk.fulfilled, (state, actions) => {
        state.user = Object.assign({}, state.user, actions.payload)
        SaveUserToLocal(actions.payload)
      })
  },
})
export const reducer = walletSlice.reducer
export const { changeLoading, setUserInfoByLocal, setChangedAccount, changeAddressLink } = walletSlice.actions
export { loginThunk, regNickNameThunk, addAddressToWebThunk, layoutThunk }
