import { IUser } from '@/entity/service.interface'
import { localUserKey } from '@/entity/system.interface'
import { setUserInfoByLocal } from '@/store/wallet'
import { Dispatch } from '@reduxjs/toolkit'
import { useEffect } from 'react'

// 自动根据session获取用户信息
const useUserLogin = (dispatch: Dispatch) => {
  // get login session
  useEffect(() => {
    try {
      console.log('start transfer login session')
      const info = sessionStorage.getItem(localUserKey)
      if (info) {
        const user = JSON.parse(info) as IUser
        dispatch(setUserInfoByLocal(user))
        // 本系统用户和Moralis不一致
        // user.token &&
        //   window.Moralis.User.become(user.token).then((payload: any) => {
        //     const address = payload.get('ethAddress') // 当前地址
        //     const accounts = payload.get('accounts') // 账号列表
        //     const ret = {
        //       objectId: payload.id,
        //       wallets:
        //         accounts && accounts.length > 0
        //           ? accounts.map((account: string, i: number) => ({ walletname: `wallet 0${i + 1}`, address: account }))
        //           : [],
        //       currentAddress: address,
        //       token: payload.get('sessionToken'),
        //     }
        //     console.log('ret---', ret)
        //     dispatch(setUserInfoByLocal(ret))
        //   })
      }
    } catch (e) {
      console.log('no save user session')
    }
  }, [])
}
export default useUserLogin
