import React from 'react'
import { Typography } from 'antd'

const { Text } = Typography
const EllipsisMiddleComponent = ({
  suffixCount,
  children,
  className,
}: {
  suffixCount: number
  children: string
  className?: string | undefined
}) => {
  const start = children.slice(0, children.length - suffixCount).trim()
  const suffix = children.slice(-suffixCount).trim()
  return (
    <Text style={{ maxWidth: '100%' }} className={className} ellipsis={{ suffix }}>
      {start}
    </Text>
  )
}
export default EllipsisMiddleComponent
