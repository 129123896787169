import { message } from 'antd'
import React from 'react'

const successSvg = (
  <svg className="icon" aria-hidden="true">
    <use xlinkHref="#icon-icon-success"></use>
  </svg>
)
const warnSvg = (
  <svg className="icon" aria-hidden="true">
    <use xlinkHref="#icon-icon-Warning"></use>
  </svg>
)
export const start = () => {
  const serverUrl = process.env.REACT_APP_MORALIS
  const id = process.env.REACT_APP_ID
  window.Moralis.start({ serverUrl, appId: id })
  // 自定义message消息
  window.message = {
    info: (content: string) =>
      message.info({
        content,
        icon: <div className="inline-block mr-2 text-sm align-middle">{successSvg}</div>,
        // duration: 0,
      }),
    success: (content: string) =>
      message.success({
        content,
        icon: <div className="inline-block mr-2 text-sm align-middle">{successSvg}</div>,
      }),
    warning: (content: string) =>
      message.warning({ content, icon: <div className="inline-block mr-2 text-sm align-middle">{warnSvg}</div> }),
  }
  window.addEventListener('error', (event) => {
    console.log(event)
  })
  // 处理异步错误
  window.addEventListener('unhandledrejection', (event) => {
    console.log(event)
  })
}
