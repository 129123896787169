import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { changeError } from './page'

//overviewTop详情页接口
interface overviewTopOpt {
  objectId:string
  slugs:string[]
}

interface favoritesOpt {
  objectId:string
  slug_name:string
}

interface overviewTopState {
  loading: boolean 
  iserror?: boolean
}

const initialState: overviewTopState = {
  loading: false,
}

const overviewTopThunk = createAsyncThunk(
  'overviewTopDetial',
  async (opt: overviewTopOpt, { dispatch }): Promise<any> => {
    dispatch(changeLoading(true))
    try {
      const overviewTopDetail = await window.Moralis.Cloud.run('getCollectionDetail', opt)
      console.log('getCollectionDetail:', overviewTopDetail)
      return overviewTopDetail
    } catch (e: any) {
      dispatch(changeError(e.message))
      throw e
    } finally {
      dispatch(changeLoading(false))
    }
  },
)

//获取收藏 watchlist
interface getFavoritesOpt {
  objectId:string
}

const getFavoritesThunk = createAsyncThunk(
  'getFavorites', 
  async (opt:getFavoritesOpt, { dispatch }): Promise<any> => {
  dispatch(changeLoading(true))
  try {
    const favoritesInfo = await window.Moralis.Cloud.run('getFavorites', opt)
    console.log('favoritesInfo:', favoritesInfo)
    return favoritesInfo
    
  } catch (e: any) {
    dispatch(changeError(e.message))
    throw e
  } finally {
    dispatch(changeLoading(false))
  }
})

//收藏某个详情 
const addFavoritesThunk = createAsyncThunk(
  'addFavorites',
  async (opt: favoritesOpt, { dispatch }): Promise<any> => {
    dispatch(changeLoading(true))
    try {
      const addFavoritesInfo = await window.Moralis.Cloud.run('addFavorites', opt)
      console.log('addFavorites:', addFavoritesInfo)
      return addFavoritesInfo
    } catch (e: any) {
      dispatch(changeError(e.message))
      throw e
    } finally {
      dispatch(changeLoading(false))
    }
  },
)

//取消收藏
const delFavoritesThunk = createAsyncThunk(
  'delFavorites',
  async (opt: favoritesOpt, { dispatch }): Promise<any> => {
    dispatch(changeLoading(true))
    try {
      const delFavoritesInfo = await window.Moralis.Cloud.run('delFavorites', opt)
      console.log('delFavorites:', delFavoritesInfo)
      return delFavoritesInfo
    } catch (e: any) {
      dispatch(changeError(e.message))
      throw e
    } finally {
      dispatch(changeLoading(false))
    }
  },
)

const overviewTopSlice = createSlice({
  name: 'overviewTop',
  initialState,
  reducers: {
    changeLoading: (state, actions) => {
      state.loading = actions.payload
    },
  },

})
export const reducer = overviewTopSlice.reducer
export const { changeLoading } = overviewTopSlice.actions
export { overviewTopThunk, getFavoritesThunk, addFavoritesThunk, delFavoritesThunk }