import React, { lazy, Suspense } from 'react'
import { Routes, Route } from 'react-router-dom'
import LayoutPage from '@/views/layout'
import SpinComponent from './components/loading/spin'
// import ErrorBoundary from './error'
const WalletPage = lazy(() => import('@/views/wallet'))
const NickNamePage = lazy(() => import('@/views/wallet/nick_name'))
const ProfilePage = lazy(() => import('@/views/profile'))
const ExplorePage = lazy(() => import('@/views/explore'))
const ClubPage = lazy(() => import('@/views/club'))
const OverviewTopPage = lazy(() => import('@/views/overviewTop'))
const OverviewUpcomingPage = lazy(() => import('@/views/overviewUpcoming'))

// import NotFound from './views/404'

function App() {
  return (
    <Suspense fallback={<SpinComponent />}>
      <Routes>
        <Route path="/" element={<LayoutPage />}>
          <Route path="profile" element={<ProfilePage />}></Route>
          <Route path="wallet" element={<WalletPage />}></Route>
          <Route path="nickname" element={<NickNamePage />}></Route>
          <Route path="explore" element={<ExplorePage />}></Route>
          <Route path="profile" element={<ProfilePage />}></Route>
          <Route path="club" element={<ClubPage />}></Route>
          <Route path="overviewTop" element={<OverviewTopPage />}></Route>
          <Route path="overviewUpcoming" element={<OverviewUpcomingPage />}></Route>
        </Route>
        <Route path="*" element={<LayoutPage />}></Route>
      </Routes>
    </Suspense>
  )
}

export default App
