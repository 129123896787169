import { Spin } from 'antd'
import React from 'react'

const SpinComponent = () => {
  return (
    <div className="w-full h-full flex items-center justify-center mt-[2rem]">
      <Spin size="large" />
    </div>
  )
}
export default SpinComponent
