import { reducer as page } from './page'
import { reducer as wallet } from './wallet'
import {reducer as topCollection} from './topCollection'
import {reducer as upComingCollection} from './upComingCollection'
import {reducer as overviewTop} from './overviewTop'
import { reducer as profile } from './profile'
export const reducers = {
  page: page,
  wallet: wallet,
  topCollection:topCollection,
  upComingCollection:upComingCollection,
  overviewTop:overviewTop,
  profile: profile,
}
