// import { IUser } from '@/entity/service.interface'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { changeError } from './page'

interface upComingCollectionOpt {
  objectId: string
  start: number
  limit: number

}

interface upComingCollectionState {
  loading: boolean 
  iserror?: boolean
}
const initialState: upComingCollectionState = {
  loading: false,
}

const upComingCollectionThunk = createAsyncThunk(
  'upComingCollectionList',
  async (opt: upComingCollectionOpt, { dispatch }): Promise<any> => {
    dispatch(changeLoading(true))
    try {
      const upComingCollectionList = await window.Moralis.Cloud.run('upComingCollection', opt)
      console.log('upComingCollectionList:', upComingCollectionList)
      return upComingCollectionList
    } catch (e: any) {
      dispatch(changeError(e.message))
      throw e
    } finally {
      dispatch(changeLoading(false))
    }
  },
)

const upComingCollectionSlice = createSlice({
  name: 'upComingCollection',
  initialState,
  reducers: {
    changeLoading: (state, actions) => {
      state.loading = actions.payload
    },
  },

})
export const reducer = upComingCollectionSlice.reducer
export const { changeLoading } = upComingCollectionSlice.actions
export { upComingCollectionThunk }