const ethers = window.Moralis.web3Library
const { FixedNumber } = ethers
export const round = (a: number | string, decimals?: number): string => {
  if (decimals === undefined) decimals = 0
  const fixa = FixedNumber.from(`${a}`)
  return fixa.round(decimals).toString()
}
export const mul = (a: number, b: number) => {
  // a * b
  const fixa = FixedNumber.from(`${a}`)
  const fixb = FixedNumber.from(`${b}`)
  return fixa.mulUnsafe(fixb).round(2).toString()
}
export const div = (a: number, b: number) => {
  // a / b
  const fixa = FixedNumber.from(`${a}`)
  const fixb = FixedNumber.from(`${b}`)
  return fixa.divUnsafe(fixb).round(2).toString()
}
// 千分位分割
export const toMicrNumber = (num: number, decimals?: number): string => {
  if (decimals === undefined) decimals = 2
  const decNum = FixedNumber.from(`${num}`).round(2)
  if (Intl) {
    return new Intl.NumberFormat('en-US').format(decNum)
  } else {
    return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
  }
}
// 格式化时间
export const formatEnTime = (time: string) => {
  try {
    return !time || time == '0' ? '--' : new Date(time).toDateString().substring(3)
  } catch (_) {
    return time
  }
}
