import { Badge, Input, Modal, Popover, Space, Spin } from 'antd'
import React, { useState } from 'react'
import classNames from 'classnames'
import avatorImg from '@/assets/avator.png'
import { useAppDispatch, useAppSelector } from '@/store/hook'
import EllipsisMiddleComponent from '../ellipsisMiddle'

// import addImg from '@/assets/wallet/add.png'
import maskImg from '@/assets/metamask.png'
import {
  // addAddressToWebThunk,
  // changeAddressLink,
  IUpdateAccountOpt,
  layoutThunk,
  regNickNameThunk,
} from '@/store/wallet'
import InputComponent from '../input'
import { useNavigate } from 'react-router-dom'
import { CheckCircleOutlined, Loading3QuartersOutlined } from '@ant-design/icons'
import { useCopyClipboard } from '@/hooks/useCopy'
import { IWalletInfo } from '@/entity/service.interface'
import './index.scss'

const CopySVG = (
  <svg className="icon w-[20px] h-[20px]" aria-hidden="true">
    <use xlinkHref="#icon-icon-copy"></use>
  </svg>
)
const DelSVG = (
  <svg className="icon w-[20px] h-[20px]" aria-hidden="true">
    <use xlinkHref="#icon-a-icon-deletewallet"></use>
  </svg>
)
const LayoutSVG = (
  <svg className="icon w-[20px] h-[20px]" aria-hidden="true">
    <use xlinkHref="#icon-a-icon-logout"></use>
  </svg>
)
const EditSVG = (
  <svg className="icon" aria-hidden="true">
    <use xlinkHref="#icon-icon-edit"></use>
  </svg>
)
const closeSvg = (
  <svg className="icon w-[20px] h-[20px]" aria-hidden="true">
    <use xlinkHref="#icon-icon-close"></use>
  </svg>
)

const antIcon = <Loading3QuartersOutlined style={{ fontSize: 12, color: 'white' }} spin />
// const addAntIcon = <Loading3QuartersOutlined style={{ fontSize: 16, color: '#282A37' }} spin />

const UserWalletComponent = ({ onShowPanel, name }: { onShowPanel?: () => void; name: string }) => {
  const dispatch = useAppDispatch()
  const navigator = useNavigate()
  const loading = useAppSelector((state) => state.wallet.loading)
  // const addWalletLoading = useAppSelector((state) => state.wallet.addLoading)
  const user = useAppSelector((state) => state.wallet.user)
  const [showModal, setShowModal] = useState(false)
  const [nickName, setNickName] = useState(name)
  const [showNanmeEdit, setShowNameEdit] = useState('') // 设置地址编辑显示
  const [editName, setEditName] = useState('') // 地址名称
  const [error, setError] = useState(false)
  const [isCopy, fnCopy] = useCopyClipboard()
  const handleDel = (item: any) => {
    // 从当前钱包列表里删除
    if (!user || !user.wallets || user.wallets.length <= 0) return
    const newWallets = user.wallets.filter((r) => r.address !== item.address)
    const opt: IUpdateAccountOpt = {
      objectId: user.objectId,
      nickName: nickName,
      addrs: newWallets,
    }
    dispatch(regNickNameThunk(opt))
      .unwrap()
      .then(() => {
        window.message.success('Deleted successfully')
      })
  }
  const handleCopy = (wallet: any) => {
    fnCopy(wallet.address)
  }
  // const handleAddWallet = () => {
  //   if (addWalletLoading) return
  //   const account = window.Moralis.account
  //   dispatch(addAddressToWebThunk(account))
  // }
  const handleEditName = () => {
    onShowPanel && onShowPanel()
    setShowModal(true)
  }
  const handleLayout = () => {
    onShowPanel && onShowPanel()
    dispatch(layoutThunk())
      .unwrap()
      .then((_) => (location.href = `${location.origin}/wallet`))
  }
  const handleChangeNickValue = (value: string, iserror: boolean) => {
    setNickName(value)
    setError(iserror)
  }
  const handleConfirmNickName = () => {
    if (!user || error || loading) return
    const opt: IUpdateAccountOpt = {
      objectId: user.objectId,
      nickName: nickName,
      addrs: user.wallets,
    }
    dispatch(regNickNameThunk(opt))
      .unwrap()
      .then(() => {
        window.message.success('edited successfully')
        handleReset(opt.nickName)
        navigator('/profile', { replace: true })
      })
  }
  const handleReset = (nickname: string) => {
    setShowModal(false)
    setEditName('')
    setShowNameEdit('')
    setNickName(nickname)
    setError(false)
  }
  const handleCancle = () => {
    setShowModal(false)
    setNickName(name)
    setError(false)
  }
  const handleChangeWalletName = (wallet: IWalletInfo) => {
    console.log('change name wallet')
    if (!editName || !user || !user.nickname) {
      setShowNameEdit('')
      return
    }
    const addrs = user.wallets.map((r) => ({
      address: r.address,
      walletname: r.address === wallet.address ? editName : r.walletname,
      main: r.main,
    }))
    const opt: IUpdateAccountOpt = {
      objectId: user.objectId,
      nickName: user.nickname,
      addrs: addrs,
    }
    dispatch(regNickNameThunk(opt))
      .unwrap()
      .then(() => {
        setShowNameEdit('')
        window.message.success('edited successfully')
        navigator('/profile', { replace: true })
      })
  }
  // const handleChangeAddress = (walllet: IWalletInfo) => {
  //   dispatch(changeAddressLink(walllet.address))
  // }
  return (
    <div className="flex flex-col">
      <div className="w-80 flex items-center p-6 border-b border-primary-gray-e5 order-1">
        <div className="border-0 w-10 h-10 bg-white rounded-full flex justify-center items-center">
          <img src={avatorImg} />
        </div>
        <div className="text-base text-primary-black font-inter-medium font-semibold ml-4">{user?.nickname || ''}</div>
      </div>
      {user &&
        user.wallets &&
        user.wallets.map((wallet, i) => (
          <div
            className={classNames(
              'flex justify-between items-center mx-4 mt-4 px-2 py-2 hover:bg-[#F6F8FF] rounded-[4px] cursor-pointer',
              {
                'order-2': wallet.main,
                'order-3': !wallet.main,
              },
            )}
            key={`${wallet.walletname}_${i}`}
            // onClick={() => handleChangeAddress(wallet)}
          >
            {/* 左侧内容 */}
            <div className="flex items-center">
              <Badge
                dot={user.currentAddress === wallet.address}
                style={{ width: '8px', height: '8px' }}
                offset={[-8, 4]}
                color="#2CB334"
              >
                <div className="border border-slate-200 w-10 h-10 bg-white rounded-full flex justify-center items-center">
                  <img src={maskImg} className="w-4 h-4 " />
                </div>
              </Badge>
              <div>
                <div className="group flex items-center ml-4 text-sm">
                  <div className="text-primary-black pt-1">
                    {showNanmeEdit === wallet.walletname ? (
                      <Input
                        autoFocus
                        placeholder={wallet.walletname}
                        className="w-32 h-5 text-xs text-primary-gray-b5 border-primary-gray-b5 shadow-none
                         focus:border-primary-gray-b5 focus:shadow-none hover:border-primary-gray-b5 hover:shadow-none text-[#282a37]"
                        onBlur={() => handleChangeWalletName(wallet)}
                        onChange={(e) => setEditName(e.target.value)}
                        onPressEnter={() => handleChangeWalletName(wallet)}
                      />
                    ) : (
                      <span className="inline-block truncate max-w-[8.5rem]">{wallet.walletname}</span>
                    )}
                  </div>
                  <div
                    className="ml-1 hidden  group-hover:inline-block text-sm"
                    onClick={() => setShowNameEdit(wallet.walletname)}
                  >
                    {EditSVG}
                  </div>
                  {/* <img
                    onClick={() => setShowNameEdit(wallet.address)}
                    src={editImg}
                    className="w-4 h-4 ml-1 hidden group-hover:inline-block"
                  /> */}
                  {wallet.main && (
                    <div className="bg-primary-blue w-10 rounded text-xs text-center ml-2 text-white">Main</div>
                  )}
                </div>
                <div className="w-20 ml-4 text-xs text-[#B5B6C0] mt-[0px]">
                  <EllipsisMiddleComponent className="text-[#B5B6C0] font-sans" suffixCount={4}>
                    {wallet.address}
                  </EllipsisMiddleComponent>
                </div>
              </div>
            </div>
            {/* 按钮内容 */}
            <div className="flex items-center">
              <Popover
                // className="p-2"
                placement="top"
                trigger="hover"
                title={null}
                content={
                  <div className="p-2 flex items-center">
                    {isCopy ? (
                      <>
                        <CheckCircleOutlined className="w-4 h-4 mr-1" style={{ color: '#2CB334' }} />
                        <span className="leading-4">Copied</span>
                      </>
                    ) : (
                      'Copy'
                    )}
                  </div>
                }
                color="white"
              >
                <div onClick={() => handleCopy(wallet)} className="w-4 h-4 cursor-pointer">
                  {CopySVG}
                </div>
                {/* <img src={copyImg} onClick={() => handleCopy(wallet)} className="w-4 h-4 cursor-pointer" alt="copy" /> */}
              </Popover>

              {!wallet.main ? (
                <Popover
                  placement="top"
                  trigger="hover"
                  title={null}
                  content={<Space className="p-2">Delete</Space>}
                  color="white"
                >
                  <div onClick={() => handleDel(wallet)} className="w-4 h-4 ml-4 cursor-pointer">
                    {DelSVG}
                  </div>
                </Popover>
              ) : null}
            </div>
          </div>
        ))}
      {/* 添加钱包 */}
      {/* <div className="p-4 flex items-center order-4 pl-6">
        <div
          className="border border-slate-200 w-10 h-10 bg-white rounded-full flex justify-center items-center cursor-pointer"
          onClick={handleAddWallet}
        >
          {addWalletLoading ? (
            <Spin className="mb-1" indicator={addAntIcon} />
          ) : (
            <img src={addImg} className="w-4 h-4" />
          )}
        </div>

        <div className="ml-4 text-primary-black text-sm cursor-pointer" onClick={handleAddWallet}>
          Add wallet
        </div>
      </div> */}
      {/* 修改昵称 */}
      <div
        className="order-5 p-6 flex items-center border-t border-t-primary-gray-e5 border-b-primary-gray-e5 cursor-pointer"
        onClick={handleEditName}
      >
        <div className="text-xl bg-white">{EditSVG}</div>
        {/* <img src={editImg} className="w-5 h-5 mr-4 bg-white" /> */}
        <div className="ml-4 text-primary-black text-sm cursor-pointer " onClick={handleEditName}>
          Edit Nickname
        </div>
      </div>
      <div
        className="order-6 p-6 flex items-center border-t border-t-primary-gray-e5 border-b-primary-gray-e5 cursor-pointer"
        onClick={handleLayout}
      >
        <div className="text-sm text-[#e62324]">{LayoutSVG}</div>
        {/* <img src={layoutImg} className="w-4 h-4 mr-4 bg-white" /> */}
        <div className="ml-4 text-primary-black text-sm cursor-pointer">Log out</div>
      </div>
      {showModal ? (
        <Modal
          visible={showModal}
          footer={null}
          closable={false}
          // title="Edit Nickname"
          title={null}
          onCancel={handleCancle}
          bodyStyle={{ padding: 0 }}
        >
          {/* <div className="w-[22rem] h-10 -mt-2 mb-8">
            <InputComponent defaultValue={nickName} onChangeValue={handleChangeNickValue} />
          </div> */}
          <div className="pt-8 px-6">
            <div className="flex justify-between">
              <div className="font-inter-medium font-semibold text-base text-primary-black mb-8">Edit Nickname</div>
              <div className="text-base text-primary-gray-b5 hover:text-primary-black" onClick={handleCancle}>
                {closeSvg}
              </div>
            </div>
            {/* <div className="w-full min-h-[2.5rem]">
              
            </div> */}
            <InputComponent
              className="w-full h-12"
              errorClassName="w-full"
              defaultValue={nickName}
              onChangeValue={handleChangeNickValue}
            />
          </div>

          <div
            className={classNames(
              'flex justify-end items-center mt-8 border-0 border-t border-t-primary-gray-e5 py-4 px-6',
            )}
          >
            <div
              className={classNames('text-sm mr-4 cursor-pointer', {
                'hover: text-primary-black': !error,
                'text-primary-gray-75': error || !nickName,
              })}
              onClick={handleCancle}
            >
              Cancel
            </div>
            <div
              className={classNames('w-20 h-10 leading-10 text-center text-white rounded shadow-lg relative', {
                'bg-primary-gray-b5 cursor-default': error || !nickName,
                'hover:bg-primary-hover-blue': !error,
                'bg-primary-blue cursor-pointer': !error,
              })}
              onClick={handleConfirmNickName}
            >
              {/* <div
                className={classNames('absolute left-[6%] top-[30%] animate-spin text-white', {
                  hidden: !loading,
                  block: loading,
                })}
              >
                {loadingSvg}
              </div> */}
              <Spin
                indicator={antIcon}
                className={classNames('absolute left-[8%] top-[16%]', {
                  hidden: !loading,
                  block: loading,
                })}
              />
              Save
            </div>
          </div>
        </Modal>
      ) : null}
    </div>
  )
}
export default UserWalletComponent
