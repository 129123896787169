import { createSlice } from '@reduxjs/toolkit'

interface IPageStore {
  loading: boolean
  error?: string
  warning?: string
}
const initialState: IPageStore = {
  loading: false,
}

const pageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    changeLoading: (state, actions) => {
      state.loading = actions.payload
    },
    changeError: (state, actions) => {
      state.error = actions.payload
    },
    changeWarning: (state, actions) => {
      state.warning = actions.payload
    },
  },
})
export const reducer = pageSlice.reducer
export const { changeLoading, changeError, changeWarning } = pageSlice.actions
