import { setChangedAccount } from '@/store/wallet'
import { Dispatch } from '@reduxjs/toolkit'
import { useEffect } from 'react'

// 监控修改小狐狸的钱包地址
const useChangeAccount = (dispatch: Dispatch) => {
  useEffect(() => {
    // console.log('window.ethereum:', window.ethereum)
    // window.ethereum &&
    //   window.ethereum.on('accountsChanged', (accounts: string) => {
    //     console.log('----:', accounts)
    //   })
    window.Moralis.enableWeb3().then(() => {
      window.Moralis.onAccountChanged((address: string) => dispatch(setChangedAccount(address)))
    })
  }, [])
}
export default useChangeAccount
