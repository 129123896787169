// import { IUser } from '@/entity/service.interface'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { changeError } from './page'

//入参
interface topCollectionOpt {
  objectId: string
  start: number
  limit: number
  sort:string
  desc:boolean
}

interface topCollectionState {
  loading: boolean 
  iserror?: boolean
}
const initialState: topCollectionState = {
  loading: false,
  
}

const topCollectionThunk = createAsyncThunk(
  'topCollectionList',
  async (opt: topCollectionOpt, { dispatch }): Promise<any> => {
    dispatch(changeLoading(true))
    try {
      const topCollectionList = await window.Moralis.Cloud.run('topCollection', opt)
      console.log('topCollectionList:', topCollectionList)
      return topCollectionList
    } catch (e: any) {
      dispatch(changeError(e.message))
      throw e
    } finally {
      dispatch(changeLoading(false))
    }
  },
)

const topCollectionSlice = createSlice({
  name: 'topCollection',
  initialState,
  reducers: {
    changeLoading: (state, actions) => {
      state.loading = actions.payload
    },
  },

})
export const reducer = topCollectionSlice.reducer
export const { changeLoading } = topCollectionSlice.actions
export { topCollectionThunk }