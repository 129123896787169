import {
  IActivityTableInfoList,
  ICollectionDetailInfo,
  ICollectionItemInfoList,
  ICollectionsList,
  IFolloweeResult,
  IFollowingResult,
  IUserInvestInfo,
  IUserPieInfo,
} from '@/entity/service.interface'
import { div, mul, round, toMicrNumber } from '@/utils'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { changeError } from './page'

interface IProfileState {
  loading: boolean
  activityTableLoading: boolean
  watchListLoading: boolean
  collections?: ICollectionsList
  investInfo?: IUserInvestInfo[] // 涨跌7D数据
  oneCollectionList?: IUserInvestInfo // 选择单个nft集合时，返回列表数据
  pieInfo?: IUserPieInfo // 饼图数据
  collectionItem?: ICollectionItemInfoList
  followingList?: IFollowingResult
  followeeList?: IFolloweeResult
}
const initialState: IProfileState = {
  loading: false,
  activityTableLoading: false,
  watchListLoading: false,
}
interface IServiceInfoOpt {
  objectId: string
  addr: string
}
type getUserInvestInfoOpt = IServiceInfoOpt & { slug?: string }
// 获取nft集合
const getUserCollectionThunk = createAsyncThunk(
  'profile_collection_list',
  async (opt: IServiceInfoOpt, { dispatch }): Promise<ICollectionsList> => {
    dispatch(changeLoading(true))
    try {
      const collectionList = await window.Moralis.Cloud.run('getUserCollectionsInfo', opt)
      // console.log('getUserCollectionsInfo:', collectionList1)
      // const collectionList = await fetch(`${process.env.REACT_APP_MORALIS}/functions/getUserCollectionsInfo`, {
      //   method: 'POST',
      //   body: JSON.stringify(opt),
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      // })
      //   .then((res) => res.json())
      //   .then((res) => res.result)
      console.log('getUserCollectionsInfo:', collectionList)
      if (collectionList.success) {
        return collectionList.data
      } else {
        throw new Error(collectionList.data.message)
      }
    } catch (e: any) {
      dispatch(changeError(e.message))
      throw e
    } finally {
      dispatch(changeLoading(false))
    }
  },
)
// 获取涨跌面板
const getUserInvestInfoThunk = createAsyncThunk(
  'profile_user_investInfo',
  async (opt: getUserInvestInfoOpt, { dispatch }): Promise<IUserInvestInfo[]> => {
    dispatch(changeLoading(true))
    try {
      const userInvestInfo = await window.Moralis.Cloud.run('getUserInvestInfo', opt)
      if (userInvestInfo.success) {
        const data = userInvestInfo.data
        const info = opt.slug
          ? [
              {
                type: 'collection',
                values: [
                  mul(data.hold_count, data.floor_price ?? 0),
                  data.hold_count,
                  data.floor_price ?? 0,
                  data.spending_eth ?? 0,
                  data.revenue_eth ?? 0,
                ],
              },
            ]
          : [
              {
                type: 'Holding Value',
                values: [round(data.hold_eth ?? 0, 2), toMicrNumber(data.hold_usd ?? 0)],
                info: 'The sum of the estimated price of each NFT held by the address.',
                day: '7D涨幅=（现在的价值-7天前同一时间的价值）/7天前同一时间的价值*100%',
                txt: 'U计价=ETH数量*当前ETH价格',
              },
              {
                type: 'ROI',
                values: [round(data.roi ?? 0 * 100, 2)],
                info: 'ROI = (Revenue - Spending)/Spending*100%',
                day: '7D涨幅=（现在的价值-7天前同一时间的价值）/7天前同一时间的价值*100%',
                txt: 'U计价=ETH数量*当前ETH价格',
              },
              {
                type: 'Revenue',
                values: [round(data.revenue_eth ?? 0, 2), toMicrNumber(data.revenue_usd ?? 0)],
                info: 'Total money the address gained by selling NFTs.',
                day: '7D涨幅=（现在的价值-7天前同一时间的价值）/7天前同一时间的价值*100%',
                txt: 'U计价=ETH数量*当前ETH价格',
              },
              {
                type: 'Spending',
                values: [round(data.spending_eth ?? 0, 2), toMicrNumber(data.spending_usd ?? 0)],
                info: 'Total money the address spent buying NFTs.',
                day: '7D涨幅=（现在的价值-7天前同一时间的价值）/7天前同一时间的价值*100%',
                txt: 'U计价=ETH数量*当前ETH价格',
              },
            ]
        return info
      } else {
        throw new Error(userInvestInfo.data.message)
      }
    } catch (e: any) {
      dispatch(changeError(e.message))
      throw e
    } finally {
      dispatch(changeLoading(false))
    }
  },
)

// 获取饼图getUserCollectionsValue
const getUserPenThunk = createAsyncThunk(
  'profile_user_collections_value',
  async (opt: IServiceInfoOpt, { dispatch }): Promise<IUserPieInfo> => {
    dispatch(changeLoading(true))
    try {
      const userCollectionsValue = await window.Moralis.Cloud.run('getUserCollectionsValue', opt)
      if (userCollectionsValue.success) {
        const data = userCollectionsValue.data
        return { count: data.count, values: data.infos }
      } else {
        throw new Error(userCollectionsValue.data)
      }
    } catch (e: any) {
      dispatch(changeError(e.message))
      throw e
    } finally {
      dispatch(changeLoading(false))
    }
  },
)

// 获取某个collection下的item数据

type getCollectionItemOpt = IServiceInfoOpt & {
  newQuery: boolean
  slug?: string
  sort_item: string
  direction: string
  offset: number
  limit: number
}
const getCollectionItemThunk = createAsyncThunk(
  'profile_user_collection_item',
  async (opt: getCollectionItemOpt, { dispatch }): Promise<ICollectionItemInfoList> => {
    dispatch(changeLoading(true))
    try {
      const userCollectionItemValue = await window.Moralis.Cloud.run('getUserCollectionsItems', opt)
      console.log('getUserCollectionsItems::', userCollectionItemValue)
      if (userCollectionItemValue.success) {
        const data = userCollectionItemValue.data
        if (typeof data === 'string') return { count: 0, items: [], newquery: opt.newQuery }
        return { count: data.count, items: data.items, newquery: opt.newQuery }
      } else if (userCollectionItemValue.data == 'no more item') {
        return { count: 0, items: [], newquery: opt.newQuery }
      } else {
        throw new Error(userCollectionItemValue.data)
      }
    } catch (e: any) {
      dispatch(changeError(e.message))
      throw e
    } finally {
      dispatch(changeLoading(false))
    }
  },
)
type tradeHistoryOpt = IServiceInfoOpt & {
  offset: number
  limit: number
  slug?: string
  action?: string
  start_time?: string
  end_time?: string
}
// 获取activity列表
const getTradeHistoryThunk = createAsyncThunk(
  'profile_user_activity_list',
  async (opt: tradeHistoryOpt, { dispatch }): Promise<IActivityTableInfoList> => {
    dispatch(changeLoading({ key: 'activity', value: true }))
    try {
      const activityList = await window.Moralis.Cloud.run('getTradeHistory', opt)
      if (activityList.success) {
        const data = activityList.data
        if (typeof data === 'string') return { count: 0, items: [] }
        // 修改下精度
        data.forEach((d: any) => {
          d.value = round(d.value, 4)
        })
        return { count: activityList.total, items: data }
      } else {
        throw new Error(activityList.data.message)
      }
    } catch (e: any) {
      dispatch(changeError(e.message))
      throw e
    } finally {
      dispatch(changeLoading({ key: 'activity', value: false }))
    }
  },
)

// 获取watchlist
const getWatchListThunk = createAsyncThunk(
  'profile_user_collection_watch',
  async (objectId: string, { dispatch }): Promise<ICollectionDetailInfo[]> => {
    dispatch(changeLoading({ key: 'watchlist', value: true }))
    try {
      const favoritesInfo = await window.Moralis.Cloud.run('getFavorites', { objectId: objectId })
      if (favoritesInfo.success) {
        // 获取收藏的collections,根据collections获取details
        const details = await window.Moralis.Cloud.run('getCollectionDetail', {
          objectId: objectId,
          slugs: favoritesInfo.data.favorites,
        })
        if (details.success) {
          if (Array.isArray(details.data)) {
            details.data.forEach((item: any) => {
              const volume = item.tag === 'upcomming' ? item.items : item.volume
              const items = item.tag === 'upcomming' ? item.volume : item.items
              // 由于返回来的floor_price有的有eth字段，需要处理下
              const floor =
                typeof item.floor_price === 'string' ? item.floor_price.replace('ETH', '').trim() : item.floor_price
              ;(item.floor_price = round(floor ?? 0, 2)), (item.volume = round(volume ?? 0, 2))
              item.holders = div(item.holders, 1000)
              item.items = div(items, 1000)
            })
            return details.data
          } else {
            return []
          }
        } else {
          throw new Error(details.data.message)
        }
      } else {
        throw new Error(favoritesInfo.data.message)
      }
    } catch (e: any) {
      dispatch(changeError(e.message))
      throw e
    } finally {
      dispatch(changeLoading({ key: 'watchlist', value: false }))
    }
  },
)

// 取消关注
type removeFavoritesOpt = {
  objectId: string
  slug_name: string
}
const removeFavoritesThunk = createAsyncThunk(
  'profile_user_wather_remove',
  async (opt: removeFavoritesOpt, { dispatch }) => {
    dispatch(changeLoading({ key: 'watchlist', value: true }))
    try {
      const removeFavoritesRet = await window.Moralis.Cloud.run('delFavorites', opt)
      console.log('removeFavoritesRet:', removeFavoritesRet)
      if (removeFavoritesRet.success) {
        return removeFavoritesRet.data
      } else {
        throw new Error('canceled fail')
      }
    } catch (e: any) {
      dispatch(changeError(e.message))
      throw e
    } finally {
      dispatch(changeLoading({ key: 'watchlist', value: false }))
    }
  },
)

// 添加follow
type addFollowUserOPt = {
  objectId: string
  master: string
}
const addFollowUserThunk = createAsyncThunk('profile_user_fllow_me', async (opt: addFollowUserOPt, { dispatch }) => {
  dispatch(changeLoading(true))
  try {
    const addFollowRet = await window.Moralis.Cloud.run('addFollower', opt)
    if (addFollowRet.success) {
      return addFollowRet.data
    } else {
      throw new Error(addFollowRet.data.message)
    }
  } catch (e: any) {
    dispatch(changeError(e.message))
    throw e
  } finally {
    dispatch(changeLoading(false))
  }
})
// 取消follow
const removeFollowUserThunk = createAsyncThunk(
  'profile_user_fllow_me_remove',
  async (opt: addFollowUserOPt, { dispatch }) => {
    dispatch(changeLoading(true))
    try {
      const delFollowRet = await window.Moralis.Cloud.run('delFollower', opt)
      if (delFollowRet.success) {
        return delFollowRet.data
      } else {
        throw new Error(delFollowRet.data.message)
      }
    } catch (e: any) {
      dispatch(changeError(e.message))
      throw e
    } finally {
      dispatch(changeLoading(false))
    }
  },
)

// 获取别人关注我的列表
const getFollowUserThunk = createAsyncThunk(
  'profile_user_fllow_me_list',
  async (objectId: string, { dispatch }): Promise<IFollowingResult> => {
    dispatch(changeLoading(true))
    try {
      const getFollowRet = await window.Moralis.Cloud.run('getFollower', { objectId })
      console.log('getFollower:', getFollowRet)
      if (getFollowRet.success) {
        if (getFollowRet.data.count) {
          const infos = getFollowRet.data.followers.map((item: any) => {
            return {
              addrs: item.get('addrs'),
              nickName: item.get('nickName'),
              avator: item.get('avator'),
              banner: item.get('banner'),
              objectId: item.id,
            }
          })
          return { count: getFollowRet.data.count, followers: infos }
        }
        return { count: 0, followers: [] }
      } else {
        throw new Error(getFollowRet.data.message)
      }
    } catch (e: any) {
      dispatch(changeError(e.message))
      throw e
    } finally {
      dispatch(changeLoading(false))
    }
  },
)
// 获取我关注人的列表
const getFolloweeUsersThunk = createAsyncThunk(
  'profile_user_fllowee_me_list',
  async (objectId: string, { dispatch }): Promise<IFolloweeResult> => {
    dispatch(changeLoading(true))
    try {
      const getFollowRet = await window.Moralis.Cloud.run('getFollowee', { objectId })
      console.log('getFollowee:', getFollowRet)
      if (getFollowRet.success) {
        if (getFollowRet.data.count) {
          const infos = getFollowRet.data.followees.map((item: any) => ({
            addrs: item.get('addrs'),
            nickName: item.get('nickName'),
            avator: item.get('avator'),
            banner: item.get('banner'),
            objectId: item.id,
          }))
          return { count: getFollowRet.data.count, followees: infos }
        }
        return { count: 0, followees: [] }
      } else {
        throw new Error(getFollowRet.data.message)
      }
    } catch (e: any) {
      dispatch(changeError(e.message))
      throw e
    } finally {
      dispatch(changeLoading(false))
    }
  },
)
// 是否已经follow
const checkIfFolloweeThunk = createAsyncThunk(
  'profile_user_followee_check',
  async (opt: addFollowUserOPt, { dispatch }): Promise<boolean> => {
    dispatch(changeLoading(true))
    try {
      const getFolloweeChecked = await window.Moralis.Cloud.run('isFollowing', opt)
      if (getFolloweeChecked.success) {
        return (
          (getFolloweeChecked.data &&
            getFolloweeChecked.data.following &&
            typeof getFolloweeChecked.data.following !== 'string') ||
          false
        )
      } else {
        throw new Error(getFolloweeChecked.data)
      }
    } catch (e: any) {
      dispatch(changeError(e.message))
      throw e
    } finally {
      dispatch(changeLoading(false))
    }
  },
)

// 添加关注
type addFavoritesOpt = {
  objectId: string
  slug_name: string
}
const addFavoritesThunk = createAsyncThunk('profile_user_wather_add', async (opt: addFavoritesOpt, { dispatch }) => {
  dispatch(changeLoading(true))
  try {
    const addFavoritesRet = await window.Moralis.Cloud.run('addFavorites', opt)
    console.log('addFavoritesRet:', addFavoritesRet)
    if (addFavoritesRet.success) {
      return addFavoritesRet.data
    } else {
      throw new Error('watched fail')
    }
  } catch (e: any) {
    dispatch(changeError(e.message))
    throw e
  } finally {
    dispatch(changeLoading(false))
  }
})

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    changeLoading: (state, actions) => {
      if (typeof actions.payload === 'boolean') {
        state.loading = actions.payload
      } else {
        switch (actions.payload.key) {
          case 'activity':
            state.activityTableLoading = actions.payload.value
            break
          case 'watchlist':
            state.watchListLoading = actions.payload.value
            break
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserCollectionThunk.fulfilled, (state, actions) => {
        const p = actions.payload
        // p.count = 2
        // p.infos = [{ collection_name: 'VEI', collection_slug: 'WEIII', floor_price: 23.11, pic_url: '' }]
        state.collections = p
      })
      .addCase(getUserInvestInfoThunk.fulfilled, (state, actions) => {
        const payload = actions.payload
        const index = payload.findIndex((r) => r.type === 'collection')
        if (index >= 0) {
          state.oneCollectionList = payload[0]
        } else {
          state.investInfo = payload
        }
      })
      .addCase(getUserPenThunk.fulfilled, (state, actions) => {
        let payload = actions.payload
        const allValue = payload.values.reduce((a, c) => a + Number(c.holding_value), 0)
        payload.values.forEach((item) => {
          item.holding_percent = `${(Number(item.holding_value) * 100) / allValue ?? 0}%`
          item.holding_value = Number(item.holding_value || 0) ?? 0
          item.holding_value_str = round(item.holding_value, 2)
        })
        // count超过9个的时候，按大小归属到其他
        if (payload.count > 9) {
          payload.values.sort((a, b) => b.holding_value - a.holding_value)
          const others = payload.values.slice(9)
          const otherValue = others.reduce((a, c) => a + c.holding_value, 0)
          const values = Array.from(payload.values.slice(0, 9))
          values.push({
            collection_name: 'other',
            holding_value: otherValue ?? 0,
            holding_percent: `${round((Number(otherValue) * 100) / allValue ?? 0, 2)}%`,
            holding_value_str: round(otherValue ?? 0, 2),
          })
          payload = { count: payload.count, values: values }
        }
        state.pieInfo = payload
      })
      .addCase(getFollowUserThunk.fulfilled, (state, actionts) => {
        const payload = actionts.payload
        state.followingList = payload
      })
      .addCase(getFolloweeUsersThunk.fulfilled, (state, actions) => {
        const payload = actions.payload
        console.log('payload----', payload)
        state.followeeList = payload
      })
      .addCase(getCollectionItemThunk.fulfilled, (state, actions) => {
        console.log('get server data', actions.payload)
        const payload = actions.payload
        payload.items.forEach((item) => {
          item.acr_time = item.acr_time === '0' ? '--' : new Date(item.acr_time).toDateString().substring(3)
        })
        state.collectionItem = payload.newquery
          ? payload
          : {
              count: !state.collectionItem?.count ? payload.count : state.collectionItem?.count,
              items: Array.from(state.collectionItem?.items || []).concat(payload.items),
              newquery: payload.newquery,
            }
      })
  },
})
export const reducer = profileSlice.reducer
export const { changeLoading } = profileSlice.actions
export {
  getUserCollectionThunk,
  getUserInvestInfoThunk,
  getUserPenThunk,
  getCollectionItemThunk,
  getWatchListThunk,
  addFavoritesThunk,
  getTradeHistoryThunk,
  addFollowUserThunk,
  removeFavoritesThunk,
  removeFollowUserThunk,
  getFollowUserThunk,
  getFolloweeUsersThunk,
  checkIfFolloweeThunk,
}
